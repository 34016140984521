import React, { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { routes, view } from './utils/router';
// 路由刷新context
const reFresh = createContext<any>(undefined);
export let useRoute = (function () {
  return useContext(reFresh);
})
function App() {
  type routelist = Array<JSX.Element | routelist>
  let [_routes,set_routes] = useState<routelist>();
  let [refresh, setrefresh] = useState<boolean>(true);
  useEffect(()=>{
    get_routes();
    setrefresh(false);
  },[refresh])
  function get_routes(){
    set_routes(make_routes(routes));
  }
  function make_routes(route:Array<view>):routelist{
    // 获取route列表
    // 如果路由存在，遍历路由，仅当存在element的对象才能够拥有页面路由
    if(route){
      return route.map((el,i)=>{
        let val:routelist = [];
        if(el.element){
          // 当页面存在时，如果存在model那么将打开model的页面，再通过model进行处理，如果没有直接显示element的页面
          val.push(<Route path={el.url} key={el.id || i}
            element={React.createElement(el.model ? el.model.default : el.element.default,el.model ? el : undefined)}
          ></Route>)
        }
        if(el.children){/* 处理子元素 */
          val.concat(make_routes(el.children))
        }
        return val
      })
    }else return [];
  }
  return (
    <div className="App">
      <Routes>
        {_routes}
      </Routes>
    </div>
  );
}

export default App;