import './trade.scss';
function Trade_svg(){
    return(
        <svg className="trade_svg" viewBox="0 0 16 16">
            <g className='out'>
                <g>
                    <path className="trade_svg_top first" d="M14.71 6 C12 0 4 0 1.29 6"></path>
                    <path className="trade_svg_top_end sencond" d="M1.29 6 l2 -0.5"></path>
                </g>
                <g>
                    <path className="trade_svg_bot first" d="M1.29 10 C4 16 12 16 14.71 10"></path>
                    <path className="trade_svg_bot_end sencond" d="M14.71 10 l-2 0.5"></path>
                </g>
            </g>
            <g className='third'>
                <path d="M6 7.5 h4"></path>
                <path d="M6 9.5 h4"></path>
                <path d="M6 5.5 l2 2"></path>
                <path d="M10 5.5 l-2 2"></path>
                <path d="M8 7.5 v4"></path>
            </g>
        </svg>
    )
}

export default Trade_svg