import React from "react";
import { useModel } from "../model/index_model";
import './fullpage.scss';
interface props{
    children:JSX.Element | Array<JSX.Element>,
    id?:string,
    className?:string | Array<string>,
    style?:React.CSSProperties
}
function Fullpage(props:props){
    let {className,id,children,style} = props;
    let {vh} = useModel();
    return(
        <div style={{...style,height:`${vh}px`}} className={['fullpage'].concat(className ? typeof className == 'string' ? className.split(' ') : className : []).join(' ')} id={id}>
            {children}
        </div>
    )
}

export default Fullpage;