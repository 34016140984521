import { business_part, cases_part, development_part, header_nav, plat_part } from "./registry";
interface li_child{
    name:string,/* 导航名称 */
    name_eng?:string,/* 英语名称 */
}
interface li_item extends li_child{
    id?:string,/* 唯一表示符 */
    url:string,/* 跳转路径，可无，主要进行非本页跳转 */
    children?:Array<tar>
}
type tar = ({id:string,url?:never} | {id?:never,url:string} | {id:string}) & li_child;

interface config_item{
    id:string,
    url:string,
    children?:Array<string | tar | {id:string,url:string}>
}

const navconfig:Array<config_item | li_item> = [
    {
        id:'home',
        url:'/',
        children:[
            plat_part.id,
            business_part.id,
            development_part.id,
            cases_part.id,
            {
                id:'more_cases',
                url:'/business_case'
            }
        ]
    },
    {
        id:'equipment',
        url:'/equipment'
    },
    {
        id:'material',
        url:'/material'
    },
    {
        id:'warehouse',
        url:'/warehouse'
    },
    {
        id:'company',
        url:'/company'
    },
    {
        id:'about_us',
        url:'/about_us',
        children:[
            'company_introduce',
            'corporate_culture',
            'management_idea',
            'member_structure',
            'honors',
            'contact_us',
            {
                id:'join_us',
                url:'/join_us'
            }
        ]
    }
]

export const home_nav:Array<li_item> = (make_nav(navconfig) as Array<li_item>);

const other_nav_config:Array<config_item | li_item> = [
    {
        id:'login',
        url:'/login',
    },
    {
        id:'recruit',
        url:'/recruit',
    }
]

export const other_nav:Array<li_item> = (make_nav(other_nav_config) as Array<li_item>);

function make_nav(list:Array<any>):Array<li_item | tar>{
    let _list:Array<li_item | tar> = [];
    for(let el of list){
        let obj:li_item | tar;
        if(typeof el === 'string'){
            let {cn,eng} = header_nav[el];
            obj = {id:el,name:cn,name_eng:eng};
        }else if(typeof el === 'object'){
            if(el.hasOwnProperty('name')){
                obj = {...el};
            }else{
                if(el.hasOwnProperty('id')){
                    let {cn,eng} = header_nav[el.id];
                    obj = {...el,name:cn,name_eng:eng};
                }else continue;
            }
        }else continue;
        if(el.hasOwnProperty('children')){
            (obj as li_item).children = (make_nav(el.children) as Array<tar>);
        }
        _list.push(obj);
    }
    return _list
}

