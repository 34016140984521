import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { view } from '../../utils/router';
import HeaderNav from '../nav/header_nav';
import RightNav from '../nav/right_nav';
import LeftNav from '../nav/left_nav';
import './index_model.scss';
const ModelContext = createContext<any>(undefined);
export const useModel = (()=>{
    return useContext(ModelContext)
})
function ModelIndex(props:view){
    // 当前页面url
    let [nowpage,setnowpage] = useState<string>('');
    // 当前距离顶部位置，视图向上缩进值
    let [top,set_top] = useState<number>(0);
    // 当页的锚点列表，
    let [anchor,set_anchor] = useState<Array<string>>([]);
    // 滑动动画持续时间，单位s
    let [dur,set_dur] = useState<number>(0.1);
    // 是否允许触发鼠标混动事件
    let [wheelable,set_wheelable] = useState<boolean>(true);
    // 顶部导航处于fixed状态的url集合
    const fixed_page = ['/'];
    // 可视高度，精确为整数，px
    const [vh,set_vh] = useState<number>(0);
    useEffect(()=>{/* 初始化 */
        // 取消鼠标滚动的默认事件
        document.getElementsByClassName('model_index_wheel_part')[0].addEventListener('wheel',(e:any)=>{
            e.preventDefault();
        },{passive:false})
        // 设置可视高度
        set_vh(window.innerHeight);
    },[])
    useEffect(()=>{/* 页面变动时动态寄存url */
        setnowpage(props.url);
        // 找到class为fullpage-wheel的元素，默认一个页面只有一个，该class标志着满屏滚动
        // 获取所有的直接子元素的id作为锚点列表
        // 高度复位
        let tar = document.getElementsByClassName('fullpage-wheel')[0];
        if(tar){
            set_anchor(Array.from(tar.children).map(el=>el.id));
        }
        set_top(0);
    },[props])
    function wheel(e:any){
        if(!wheelable) return;
        let head_h = document.getElementsByClassName('header_nav')[0].clientHeight
        let mtop:number;
        let dur:number = 0;
        set_wheelable(false);
        if(e.deltaY > 0){
            if(!fixed_page.includes(nowpage) && top < head_h){
                dur = 0.15;
                mtop = head_h;
            }else if(fixed_page.includes(nowpage) || !fixed_page.includes(nowpage) && top >= head_h){
                dur = anchor && anchor.length !== 0 ? 0.4 : 0.15;
                mtop = anchor && anchor.length !== 0 ? top + vh : top + 150;
                mtop = mtop > (document.body.scrollHeight - vh) ? (document.body.scrollHeight - vh) : mtop;
            }
        }else if(e.deltaY < 0){
            if(top - head_h <= 150){
                dur = 0.15;
                mtop = 0;
            }else{
                dur = anchor && anchor.length !== 0 ? 0.4 : 0.15;
                mtop = anchor && anchor.length !== 0 ? top - vh : top - 150;
                mtop = mtop < 0 ? 0 : mtop;
            }
        }
        set_dur(dur);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            set_top(mtop);
            timer = setTimeout(() => {
                set_wheelable(true);
            }, (dur * 1000 - 50));
        }, 50);
    } 
    function choice_anchor(id:string){
        if(anchor && anchor.length !== 0 && anchor.indexOf(id) !== -1){
            set_dur(0.4)
            setTimeout(() => {
                let tar = document.getElementById(id);
                if(tar){
                    set_top(tar.offsetTop);
                }
            }, 50);
        }
    }
    function retop(){
        set_wheelable(false);
        set_dur(0.4);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            set_top(0);
            timer = setTimeout(() => {
                set_wheelable(true);
            }, 350);
        }, 50);
    }
    function rebot(){
        set_wheelable(false);
        set_dur(0.4);
        let timer = setTimeout(() => {
            clearTimeout(timer);
            set_top(document.body.scrollHeight - vh);
            timer = setTimeout(() => {
                set_wheelable(true);
            }, 350);
        }, 50);
    }
    return(
        <ModelContext.Provider value={{page:nowpage,vh,top,dur,setanchor:choice_anchor,retop,rebot}}>
            <div className='model_index'>
                <div className="model_index_wheel_part" onWheel={wheel}>
                    <div style={{transform:`translateY(-${top}px)`,transitionDuration:`${dur}s`}}>
                        <HeaderNav fixed={fixed_page.includes(nowpage)}></HeaderNav>
                        {props.element && React.createElement(props.element.default)}
                    </div>
                </div>
                {/* <LeftNav></LeftNav> */}
                <RightNav></RightNav>
            </div>
        </ModelContext.Provider>
    )
}

export default ModelIndex;