import { useEffect, useState } from 'react';
import CardBox3D, { autoplay } from '../../../../components/cardbox3d/cardbox3d';
import Fullpage from '../../../../components/fullpage/fullpage';
import InView from '../../../../components/inview/inview';
import Title from '../../../../components/title/title';
import { plat_part } from '../../../../utils/registry';
import './platform_introduction.scss';
function PlatformIntroduction() {
    const { id, cn, eng, introduct_title, introduct, img, bg } = plat_part;
    let [show, set_show] = useState<boolean>(false);
    let [showtxt, set_showtxt] = useState<string>('');
    let [timer, set_timer] = useState<any>();
    let [autoplay, set_autoplay] = useState<autoplay | undefined>();
    useEffect(() => {
        if (show) {
            set_showtxt(introduct.cn.slice(0, 1));
            set_autoplay({ sx: 0.4, sy: 0.5, ex: 1, ey: 0.5, dur: 4, z: true })
        } else {
            set_showtxt('');
            set_autoplay(undefined);
        }
    }, [show])
    useEffect(() => {
        if (showtxt) {
            if (showtxt.length == introduct.cn.length) return;
            let timer = setTimeout(() => {
                clearTimeout(timer);
                set_showtxt(introduct.cn.slice(0, showtxt.length + 1))
            }, Math.random() * 100);
            set_timer(timer);
        } else {
            clearTimeout(timer);
            set_timer(null);
        }
    }, [showtxt])
    return (
        <Fullpage id={id} style={{ backgroundImage: `url(${bg})` }}>
            <Title name={cn} name_eng={eng}></Title>
            <InView className={id} set={set_show}>
                <CardBox3D className="plat_img" autoplay={autoplay}>
                    <img src={img}></img>
                </CardBox3D>
                <div className='right_part'>
                    <div className='title'>
                        {introduct_title.cn}
                    </div>
                    <div className='introduct'>
                        <div><span>{showtxt}</span>{showtxt.length !== introduct.cn.length && <span className='introduct_end'>_</span>}</div>
                    </div>
                </div>
            </InView>
        </Fullpage>
    )
}

export default PlatformIntroduction;