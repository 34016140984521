import Swiper from "./pages/swiper/swiper";
import PlatformIntroduction from "./pages/platform_introduction/platform_introduction";
import Bussiness from "./pages/bussiness/bussiness";
import Development from "./pages/development/development";
import Cases from "./pages/cases/cases";
function Index(){
    return(
        <div className="home fullpage-wheel">
            <Swiper></Swiper>
            <PlatformIntroduction></PlatformIntroduction>
            <Bussiness></Bussiness>
            <Development></Development>
            <Cases></Cases>
        </div>
    )
}

export default Index;