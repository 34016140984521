import './digital_technology.scss';
function DigitalTechnology_svg(){
    return(
        <svg className="digital_technology_svg" viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="2" className="dt_circle"></circle>
            <g className='sencond'>
                <path d="M8 3 A4 8 -45 1 0 13 8 A4 8 -45 0 0 8 3"></path>
                <path d='M8 13 A4 8 45 1 0 3 8 A4 8 45 0 0 8 13'></path>
            </g>
        </svg>
    )
}

export default DigitalTechnology_svg