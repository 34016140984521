import { useEffect, useState } from "react";
import { useModel } from "../model/index_model";
import './right_nav.scss';
function RightNav(){
    let {top,retop,rebot} = useModel();
    let [show,set_show] = useState<boolean>(false);
    useEffect(()=>{
        if(top > 0 && !show) set_show(true);
        else if(top === 0 && show) set_show(false);
    },[top])
    return(
        <div className={['right_nav',show?'show':''].join(' ')}>
            <div className="totop" onClick={retop}>
                <svg viewBox="0 0 16 16">
                    <path d="M8 11 h5 q3 0 1 -2.3 l-4 -4.7 q-2 -2 -4 0 l-4 4.7 q-2 2.3 1 2.3 h5" stroke="#aaa" fill="#aaa"></path>
                </svg>
            </div>
            <div className="tobot" onClick={rebot}>
                <svg viewBox="0 0 16 16">
                    <path d="M8 5 h5 q3 0 1 2.3 l-4 4.7 q-2 2 -4 0 l-4 -4.7 q-2 -2.3 1 -2.3 h5" stroke="#aaa" fill="#aaa"></path>
                </svg>
            </div>
        </div>
    )
}

export default RightNav;