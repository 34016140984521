import Fullpage from "../../../../components/fullpage/fullpage";
import Title from "../../../../components/title/title";
import Wave from "../../../../components/wave/wave";
import { cases_part } from "../../../../utils/registry";
import './cases.scss';
function Cases(){
    let {id,cn,eng,items} = cases_part;
    return(
        <Fullpage id={id}>
            <Wave className="title_box" color="rgb(255,152,24)" incolor={['rgb(255, 170, 67)','rgb(255, 199, 130)','rgb(255, 226, 190)']}>
                <Title name={cn} name_eng={eng}></Title>
            </Wave>
            <div className={id}>
                {
                    (items.length>=0 && items.length <= 4) && items.map((el:any,i:number)=>{
                        return <div className="cases_item" key={i}>
                            <img src={el.img}></img>
                        </div>
                    })
                }
            </div>
        </Fullpage>
    )
}

export default Cases;