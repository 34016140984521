import { useEffect, useRef, useState } from 'react';
import './swiper.scss';
import Fullpage from '../../../../components/fullpage/fullpage';
import { swiper } from '../../../../utils/registry';

type direction = 'prev'|'next';
function Swiper(){
    // 当前索引
    let [active,set_active] = useState<number>(0);
    // 是否允许旋转
    let [turnable,set_turnable] = useState<boolean>(true);
    // 旋转方向
    let [turn_direction,set_turn_direction] = useState<direction>('next');
    // 鼠标点击起始点x轴
    let [sx,set_sx] = useState<null|number>(null);
    // x轴移动距离
    let [mx,set_mx] = useState<number>(0);
    // 计时器
    const timer = useRef<any>(null);
    useEffect(()=>{
        start(active);
        return(()=>{
            clearInterval(timer.current);
        })
    },[])
    function start(index:number){/* 启动计时器 */
        timer.current = setInterval(()=>{
            // 修改index防止react数据不刷新问题
            index = index === swiper.length - 1 ? 0 : index + 1;
            turn(index);
        },3000)
    }
    function re_start_turn(index:number,direction?:direction){/* 重新启动方法 */
        // 停止计时器后进行旋转，旋转结束后重启计时器
        let time = timer.current;
        if(time) clearInterval(timer.current);
        turn(index,direction).then((index)=>{
            if(time) start(index);
        })
    }
    function turn_prev(){/* 旋转至上一个 */
        re_start_turn(active === 0 ? swiper.length - 1 : active - 1,'prev');
    }
    function turn_next(){/* 旋转至下一个 */
        re_start_turn(active === swiper.length - 1 ? 0 : active + 1);
    }
    function turn(index:number,direction:direction='next'){/* 旋转方法 */
        return new Promise<number>((resolve,reject)=>{
            // 防抖，避免多次旋转出现问题，设定方向
            if(!turnable) return;
            set_turnable(false);
            set_turn_direction(direction);
            function _turn(num:number){/* 旋转递归方法 */
                if(num === index){
                    // 当旋转至目标索引时，可以进行旋转，并回调，这里赋值active，避免react数据不刷新问题
                    set_turnable(true);
                    active = index;
                    resolve(index)
                }else{
                    // 根据旋转方向，逐个索引进行旋转
                    num = direction == 'next' ? num === swiper.length - 1? 0 :num + 1:num === 0 ? swiper.length - 1 : num - 1;
                    set_active(num);
                    setTimeout(() => {
                        _turn(num)
                    }, (950 / Math.abs(active - index)));
                }
            }
            setTimeout(() => {/* 小定时防止设定方向后仍处于上一个状态，导致behind的class没有发挥作用 */
                _turn(active);
            }, 50);
        })
    }
    function mousedown(e:any){/* 鼠标按下 */
        // 取消计时器，防止继续轮播导致鼠标移动的效果失效，记录初始点的x轴
        if(!turnable) return;
        let time = timer.current;
        if(time) clearInterval(timer.current);
        set_sx(e.clientX);
    }
    function mouseup(e:any){/* 鼠标抬起或者离开 */
        // 如果没有sx初始点位，那么将不会触发抬起方法，因为没有意义
        if(!sx && sx!==0) return;
        // 分别根据结束点位判断上下的旋转，兼顾点击后立刻松开的方法重启计时器
        if(e.clientX > sx) turn_prev()
        else if(e.clientX < sx) turn_next();
        else start(active);
        set_sx(null);
    }
    function mousemove(e:any){/* 鼠标移动 */
        if(!sx && sx!==0) {/* 在没有初始点时防止移动 */
            set_mx(0);
            return;
        }
        // 获取目前屏幕宽度，根据计算公式实现在按下时鼠标移动轮播图跟随移动
        let w = document.getElementsByClassName('swiper_mask')[0].clientWidth;
        set_mx((e.clientX - sx) / w * 60)
    }
    return(
        <Fullpage className="home_swiper" id='swiper'>
            {swiper.length > 3 ? <div className={['swiper_box',sx || sx === 0 ? 'moving':''].join(' ')} style={{transform:`rotateY(${mx}deg)`}}>
                {swiper.map((el,i)=>{
                    return <div className={['swiper_item',active === i ? 'active'
                        :active === 0 && i === swiper.length - 1 || i === active - 1 ? 'prev'
                        :active === swiper.length - 1 && i === 0 || i === active + 1 ? 'next'
                        :`${turn_direction}_behind`].join(' ')} key={i} >
                        <img src={el} alt=""/>
                    </div>
                })}
            </div>:<div></div>}
            <div className='left_btn swiper_btn' onClick={turn_prev}>
                <svg viewBox="0 0 50 50">
                    <path d='M37 27 h-24 l8 -6' strokeLinecap='round' stroke='#fff' fill='none'></path>
                </svg>
            </div>
            <div className='right_btn swiper_btn' onClick={turn_next}>
                <svg viewBox="0 0 50 50">
                    <path d='M13 27 h24 l-8 -6' strokeLinecap='round' stroke='#fff' fill='none'></path>
                </svg>
            </div>
            <div id='swiper_mask' className={['swiper_mask',sx || sx === 0 ? 'moving':''].join(' ')} onMouseDown={mousedown} onMouseUp={mouseup} onMouseLeave={mouseup} onMouseMove={mousemove}></div>
        </Fullpage>
    )
}

export default Swiper;
/* 
    轮播图设计理念
    该轮播图只在数量为4或以上时才能够进行使用
    将轮播的3d分为4个方位，前后左右，左右为待转态，正面为显示态，其余均为后方
    以四边形的效果进行旋转，在旋转后，所有状态进行变动，固定将索引值为显示态，索引上下为待转态，当索引值为临界值的时候待转态为最初或最后的值实现无缝循环
    将旋转点设置在四边形的立体中心点，以四边形的一边进行显示
    由于旋转需要角度的设置，在设置中无法实现360转动的同时在负方向立刻变为正常向
    主要是在于处理右边或者左边转为后方，或者后方转为左边或者右边的情况
    为了有更好的旋转而不用旋转360度，这里将后方分为左后方和右后方
    在方向旋转下，只处理一个后方，由于转为后方的动画是0s的，只有后方转为左或者右的动画需要处理
    那么在设置旋转方向后通过设置后方为左后方或者右后方后，再一定延迟执行旋转动画，即不会出现旋转360度的情况

    通过interval实现轮播图，为了避免react执行方法使用的是当前态的问题，需要不断地更新索引值来同步索引
    而索引的变动在旋转的公共方法中执行
    旋转的公共方法主要接受目标索引与方向，默认方向为下一个
    在一定延迟的基础上，逐步将当前索引以1为单位的转为目标的索引，动画恒定为950ms另外50ms延迟总1s
    为了避免旋转的影响，加入是否允许转动来实现防抖的功能
    左右按钮在使用公共方法转动一位的基础上，为了防止轮播计时导致多次转动的影响，在点击转动后将会取消掉计时器并在转动结束后重启计时器

    优化效果，为了实现类似于鼠标拖动后切换轮播的效果
    在鼠标点击后记录x轴，并且在鼠标在按下移动时，轮播图3d会有转动的效果
    鼠标移动只在有初始的x轴生效，根据移动的差值来根据一定公式实现旋转，该过程由于style动态变动并不需要transition
    在松开鼠标后，根据结束坐标判断是上一张转动还是下一张转动，或者点击立刻移开导致的相同时的问题
    由于上下转动方法都已经默认会重启计时器，因此只需要在相同时加入重启计时器即可
    结束后由于索引为上一个会有一个复位的效果，那么为了防止这个效果，让其更加流畅，只需要在转动到的角度复位转动即可
    一个向左复位和一个向右转动动态下会导致的是较为流畅转动
    设置方法监听设置在新设的遮罩层中，由于主体可能会触发左右转动的点击事件，设置在转动主体会导致转动时可能由于3d效果而失去焦点触发离开事件
 */