import InView from "../inview/inview";
import './title.scss';
interface props{
    name:string,
    name_eng?:string,
}
function Title(props:props){
    let {name,name_eng} = props;
    return(
        <InView className="com_title">
            <div className="title_cn">{name}</div>
            {name_eng ? <div className="title_eng">-{name_eng}-</div> : undefined}
        </InView>
    )
}

export default Title;