interface element{
    default:string
}

export interface view{
    url:string,/* 网页路劲 */
    name:string,/* 名称 */
    name_eng?:string,/* 名称英文 */
    element?:element,/* 组件路径 */
    model?:element,/* 模版路径 */
    query?:Array<string>,/* 请求 */
    roles?:Array<string>,/* 角色权限 */
    permissions?:Array<string>,/* 权限 */
    children?:Array<view>,/* 子页面 */
    id?:string | number,/* 唯一字符 */  
}
/* 展示优先级，子页面模版、父页面模版、子页面、父页面 */
let model = require('../components/model/index_model');
export const routes:Array<view> = [
    {
        url:'/',
        name:'主页',
        element:require('../views/index/index'),
        model,
    },
    {
        url:'/about_us',
        name:'关于我们',
        element:require('../views/about_us/index'),
        model,
    }
]