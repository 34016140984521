
import { useEffect, useState } from 'react';
import './wave.scss';
interface props{
    children:JSX.Element,
    className?:string | Array<string>,
    color:string,
    incolor:string | Array<string>,
}
function Wave(props:props){
    let {children,className,color,incolor} = props;
    let [radius,set_radius] = useState<Array<string>>();
    useEffect(()=>{
        let _radius:Array<Array<number>> = []
        if(Array.isArray(incolor)){
            incolor.forEach((el:any,i:number)=>{
                _radius.push(get_radius(_radius[i-1]))
            })
            
        }else if(typeof incolor === 'string'){
            _radius.push(get_radius());
            _radius.push(get_radius());
        }
        let radius = _radius.map((el:any)=>{
            return el.map((ee:any,ii:number)=>{
                return ee + `%${ii==3 ? ' / ':''}`
            }).join(' ')
        })
        set_radius(radius);
    },[])
    function get_radius(last:Array<number> = []){
        let list:Array<number> = [];
        for(let i = 0;i<8;i++){
            list.push(get_random(last[i]))
        }
        return list
    }
    function get_random(last:number = 0){
        return Math.floor(Math.random() * (40 - last) + last + 10);
    }
    return(
        <div className={['wave'].concat(className ? typeof className == 'string' ? className.split(' ') : className : []).join(' ')} style={{backgroundColor:color}}>
            {children && children}
            {radius && radius.map((el:any,i:number)=>{
                return <div className='wave_item' key={i} style={{borderRadius:el,backgroundColor:typeof incolor == 'string' ? i == 0 ? incolor : '#fff' : incolor[i]}}></div>
            })}
        </div>
    )
}

export default Wave;