function LOGO_svg(){
    return(
        <svg viewBox="0 0 100 100" className="logo_svg">
            <path d="M12 44 l30 -26 Q 50 10 58 18 l30 26" strokeLinecap="round" stroke="rgb(248, 161, 9)" strokeWidth="4" fill="none"></path>
            <path d="M16 48 v32 A8 8 0 0 0 24 88 h52 A8 8 0 0 0 84 80 v-32" strokeLinecap="round" stroke="rgb(248, 161, 9)" strokeWidth="4" fill="none"></path>
            <circle cx="28" r="4" cy="42" fill="rgb(248, 161, 9)"></circle>
            <circle cx="72" r="4" cy="42" fill="rgb(248, 161, 9)"></circle>
            <rect x="36" y="52" width="12" height="12" fill="rgb(22, 229, 87)"></rect>
            <rect x="52" y="52" width="12" height="12" fill="rgb(22, 229, 87)"></rect>
            <rect x="36" y="68" width="12" height="12" fill="rgb(22, 229, 87)"></rect>
            <rect x="52" y="68" width="12" height="12" fill="rgb(22, 229, 87)"></rect>
        </svg>
    )
}

export default LOGO_svg;