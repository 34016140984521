import React from "react"
let toelement = (e:any)=> React.createElement(e.default);
export interface coordinate{
    x:number,
    y:number,
    cn:string
}
interface _txt{
    cn:string,
    eng:string,
    img?:string,
    img_active?:string,
    svg?:JSX.Element,
    [key:string]:any,
}
/* 网站基础信息 */
export const web_basic = {
    name:'众田科技',
    fullname:'众田（广州）科技有限公司',
    eng:'ZhongTian Technology',
    fullname_eng:'ZhongTian (GZ) Technology Co., Ltd',
}
/* 顶部导航中各导航文本 */
export const header_nav:{[key:string]:_txt} = {
    home:{
        cn:'首页',
        eng:'Home',
    },
    plat:{
        cn:'平台介绍',
        eng:'Platform Introduction'
    },
    business:{
        cn:'业务模块',
        eng:"Business"
    },
    development:{
        cn:'公司发展',
        eng:'Company Development'
    },
    cases:{
        cn:'经营案例',
        eng:'Business Case'
    },
    more_cases:{
        cn:'更多案例',
        eng:'More Cases',
    },
    equipment:{
        cn:'设备',
        eng:'Equipment'
    },
    material:{
        cn:'材料',
        eng:'Material'
    },
    warehouse:{
        cn:'仓库',
        eng:'Warehouse'
    },
    company:{
        cn:'企业',
        eng:'Company'
    },
    about_us:{
        cn:'关于我们',
        eng:"About Us"
    },
    company_introduce:{
        cn:'公司介绍',
        eng:'Company Introduce'
    },
    corporate_culture:{
        cn:'企业文化',
        eng:'Corporate Culture'
    },
    management_idea:{
        cn:'经营理念',
        eng:'Management Idea'
    },
    member_structure:{
        cn:'成员框架',
        eng:'Member Structure'
    },
    honors:{
        cn:'公司荣誉',
        eng:'Company Honors'
    },
    contact_us:{
        cn:'联系我们',
        eng:'Contact Us'
    },
    join_us:{
        cn:'人才招募',
        eng:'Join Us'
    },
    login:{
        cn:'登录',
        eng:'Login'
    },
    recruit:{
        cn:'人才招聘',
        eng:'Recruit'
    }
}
/* 顶部logo名称 */
export const header_logo:_txt = {
    cn:web_basic.name,
    eng:web_basic.eng,
    svg:toelement(require('../components/svg/logo'))
}
/* 轮播图 */
export const swiper = [
    require('../assets/image/swiper/1.jpg'),
    require('../assets/image/swiper/2.jpg'),
    require('../assets/image/swiper/3.jpg'),
    require('../assets/image/swiper/4.jpg'),
    require('../assets/image/swiper/5.jpg'),
]
/* 首页-业务模块 */
export const business_part:_txt = {
    ...header_nav.business,
    id:'business',
    items:[
        {
            cn:'数字科技',
            eng:`digital technology`,
            text:`${web_basic.name}拥有先进系统开发团队，现已开发有贸易、无车（无船）承运人、物业管理、物流等多个数字平台，能为客户提供快速、优质、多元化、综合统计、大数据服务等现成的系统平台，也可以贴合客户要求，为客户量身订做专门的数字系统平台，随时为客户的业务保驾护航。`,
            svg:toelement(require('../components/svg/digital_technology'))
        },
        {
            cn:'产业园',
            eng:`industrial parks`,
            text:`${web_basic.name}在全国各地拥有各类型的高新产业园区、科技产业园、数字产业园、物流产业园等管理园区，为进入园区的客户提代各种业务扶持计划和优惠的财税统筹方案，也能为进入园区的各中小型企业提供资金帮扶，为各客户提供一个高速、高效、高质、高优惠的产业孵化基地。`,
            img:require('../assets/image/icon/industrial_park.png'),
            img_active:require('../assets/image/icon/industrial_park_active.png'),
        },
        {
            cn:'贸易',
            eng:`trade`,
            text:`${web_basic.name}提供各类大宗商品的交易平台，有多年的大宗商品贸易经验，在钢材、煤炭方面顾为突出，拥有一定的深入了解和行业资源。${web_basic.name}拥有完善的贸易操作团队，能为客户提供完善的贸易交易平台服务，并提供专业的贸易相关问题的咨询和解答，提供完善的解决方案。`,
            svg:toelement(require('../components/svg/trade'))
        },
        // {
        //     cn:'人力',
        //     eng:'human_resources',
        //     text:`${web_basic.name}拥专业的人力资源服务队伍，有丰富的人力资源和灵活用工操作管理经验，能为客户更好提高劳动效率，充分发挥核心竞争力，增强企业应变能力。公司拥有自主研发的管理系统，能帮助管理者通过有效组织管理降低成本和加速增长来创造价值链利润，帮助公司实现全面的员工规范管理，实现完善的薪酬计算和缴纳机制，建立合法合规的合同体制，实现企业人力管理的无忧服务。`
        // }
    ]
}
/* 首页-平台介绍模块 */
export const plat_part:_txt = {
    ...header_nav.plat,
    id:'plat',
    bg:require('../assets/image/bg/ring.jpg'),
    img:require('../assets/image/bg/cloudworld.jpg'),
    introduct_title:{
        cn:web_basic.name,
        eng:web_basic.eng,
    },
    introduct:{
        cn:`${web_basic.name}致力于将科技与物业相结合，以物业为核心，围绕着${business_part.items.map((el:any)=>el.cn).join('、')}等三个主要业务模块，坐立于广州开展相关业务。`,
        eng:`${web_basic.eng} is committed to combining technology with property management, with property management as the core, focusing on three main modules: ${business_part.items.map((el:any)=>el.eng).join(', ')}, and operating related businesses in Guangzhou.`,
    }
}
/* 首页-公司发展，地图省会坐标 */
const coordinate = [
    {x:1400,y:1373,cn:'广州'},
    {x:1200,y:1393,cn:'南宁',q:{x:1295,y:1330,l:222}},
    {x:1630,y:1213,cn:'福州',q:{x:1490,y:1257,l:290}},
    {x:1488,y:1120,cn:'南昌',q:{x:1420,y:1238,l:270}},
    {x:1484,y:770,cn:'济南',q:{x:1400,y:1066,l:610}},
    {x:1380,y:864,cn:'郑州',q:{x:1410,y:1118,l:510}},
    {x:1328,y:733,cn:'太原',q:{x:1400,y:1049,l:644}},
    {x:1025,y:826,cn:'兰州',q:{x:1300,y:1040,l:680}},
    {x:1288,y:613,cn:'呼和浩特',q:{x:1380,y:988,l:770}},
]
/* 首页-公司发展 */
export const development_part:_txt = {
    ...header_nav.development,
    id:'development',
    text:{
        cn:`宜昌市${web_basic.name}有限责任公司总部坐落于广州，业务范围覆盖我国${coordinate.map((el:any)=>el.cn).join('、')}等多个省份的省会城市，有着广泛的用合作公司与用户。`,
        eng:``
    },
    map:require('../assets/image/bg/bigmap.jpg'),
    coordinate,
}

/* 首页-经营案例 */
export const cases_part:_txt = {
    ...header_nav.cases,
    id:'cases',
    items:[
        {
            cn:'园区案例',
            img:require('../assets/image/bg/park.jpg')
        },
        {
            cn:'贸易案例',
            img:require('../assets/image/bg/trade.jpg')
        },
        {
            cn:'仓储案例',
            img:require('../assets/image/bg/storage.jpg')
        },
        {
            cn:'物流案例',
            img:require('../assets/image/bg/transport.jpg')
        },
    ]
}